(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('ChargePerHouseController', ChargePerHouseController);

    ChargePerHouseController.$inject = ['$translate', 'IssuingClient', 'CustomChargeType', '$mdDialog', 'Resident', '$rootScope', '$scope', '$state', 'Charge', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'House', 'CommonMethods', '$localStorage', 'Modal', '$timeout', 'Principal', 'globalCompany', 'Company', '$sce'];

    function ChargePerHouseController(translate, IssuingClient, CustomChargeType, $mdDialog, Resident, $rootScope, $scope, $state, Charge, ParseLinks, AlertService, paginationConstants, pagingParams, House, CommonMethods, $localStorage, Modal, $timeout, Principal, globalCompany, Company, $sce) {

        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.isEditing = false;
        vm.isReady = false;
        vm.downloading = false;
        vm.authorities = $localStorage.houseSelected.roleResident;
        vm.charges = [];
        vm.chargeFilter = "PENDING";

        vm.createCreditNote = function (chargeId) {
            var id = CommonMethods.encryptIdUrl(chargeId);
            $state.go("houseAdministration.chargePerHouse.newCreditNote", {chargeId: id});
        };

        function loadCompany() {
            Company.get({id: globalCompany.getId()}, function (data) {
                vm.company = data;
            }, function (error) {
                console.error("Error al cargar la información de la compañía:", error);
            });
        }

        loadCompany();
        vm.loadPage = loadPage;

        vm.resendEmail = function (document) {
            Modal.confirmDialog("¿Está seguro que desea volver a enviar por correo el documento?", "", function () {
                var data = {
                    transactionId: document.creditNoteId,
                    documentType: "Electronic Credit Note",
                    clientId: document.houseId
                };
                IssuingClient.resendDocument(data,
                    function (data) {
                        Modal.toast("Se reenvió el correo correctamente");
                    }, function (error) {
                    }
                );
            });
        };
        var houseId;
        Principal.identity().then(function (account) {
            vm.account = account;
            switch (account.authorities[0]) {
            case "ROLE_MANAGER":

                $rootScope.mainTitle = "Contabilidad filiales";
                houseId = $localStorage.houseSelected.id;
                break;
            case "ROLE_ACCOUNTANT":
                $rootScope.mainTitle = "Contabilidad filiales";
                houseId = $localStorage.houseSelected.id;
                break;
            case "ROLE_USER":
                $rootScope.mainTitle = translate.instant("aditumApp.financesResidentAccount.subsidiaryDebts.title");
                $rootScope.active = "chargesResidentAccount";
                houseId = globalCompany.getHouseId();
                break;
            case "ROLE_OWNER":
                $rootScope.mainTitle = translate.instant("aditumApp.financesResidentAccount.subsidiaryDebts.title");
                $rootScope.active = "chargesResidentAccount";
                houseId = globalCompany.getHouseId();
                break;
            }
            loadAll();
        });

        vm.cancelInvoice = function (charge) {

            // Charge.
        };

        vm.download = function () {
            vm.downloading = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.downloading = false;
                });
            }, 1000);
        };

        vm.createPayment = function () {
            $state.go('generatePayment');
        };
        vm.datePassed = function (cuota) {
            var rightNow = new Date();
            var chargeDate = new Date(moment(cuota.date));
            return ((chargeDate.getTime() > rightNow.getTime()));
        };

        vm.edit = function () {
            var result = {};

            function updateCharge(chargeNumber) {
                if (chargeNumber < vm.charges.length) {
                    var cuota = vm.charges[chargeNumber];
                    if (cuota.leftToPay != 0) {
                        cuota.type = parseInt(cuota.type);
                        cuota.companyId = globalCompany.getId();
                        cuota.ammount = cuota.leftToPay;
                        Charge.update(cuota, function (charge) {
                            result = charge;
                            updateCharge(chargeNumber + 1);
                        });
                    }
                } else {
                    House.get({
                        id: $localStorage.houseSelected.id
                    }, onSuccess);
                }

                function onSuccess(house) {
                    Modal.toast("Se han actualizado las cuotas correctamente.");
                    $rootScope.houseSelected = house;
                    $localStorage.houseSelected = house;
                    loadAll();
                    Modal.hideLoadingBar();
                    vm.isEditing = true;
                }
            }

            var allGood = 0;
            angular.forEach(vm.charges, function (charge, i) {
                if (charge.valida == false) {
                    allGood++;
                }
            });
            if (allGood == 0) {
                Modal.confirmDialog("¿Está seguro que desea modificar las cuotas?", "",
                    function () {
                        Modal.showLoadingBar();
                        updateCharge(0);
                    });

            } else {
                Modal.toast("Alguna de las cuotas tiene un formato inválido.");
            }
        };

        vm.showPaymentModal = function (charge) {
            if (!charge || !charge.billNumber || !charge.date || !charge.total) {
                return;
            }
            if($localStorage.bankGTIPayment==null){
                $state.go('chargePerHouse-residentAccount.defineBankGtiSinpe');
            }else{
                vm.bankNumber = $localStorage.bankGTIPayment.Telefono;
                var formattedDate = moment(charge.date).format('MM-YYYY');
                var message = translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.PAYMENT_CONFIRMATION_MESSAGE')
                .replace('{billNumber}', charge.billNumber)
                .replace('{formattedDate}', formattedDate)
                .replace('{total}', $rootScope.fMoney(charge.leftToPay));

                $mdDialog.show(
                $mdDialog.confirm()
                    .title(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.PAYMENT_CONFIRMATION_TITLE'))
                    .textContent(message)
                    .ariaLabel(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.PAYMENT_CONFIRMATION_TITLE'))
                    .ok(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.CONFIRM'))
                    .cancel(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.CANCEL'))
            ).then(function () {
                procesarPago(charge);
            }, function () {
                console.log(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.CANCEL_MESSAGE'));
            });
            }
        };

        function procesarPago(charge) {
            var paymentRequest = {
                Cliente: $localStorage.houseSelected.id,
                Deudor: vm.account.id,
                Contrato: vm.account.id,
                NomDeudor: "",
                Periodo: moment(charge.date).format('YYYYMMDD'),
                NumTelefono: "",
                Factura: charge.billNumber,
                Monto: Math.round(charge.leftToPay),
                Vencimiento: moment(charge.date).format('YYYY-MM-DD'),
                ChargeId: charge.id,
                CompanyId: globalCompany.getId()
            };
            Modal.showLoadingBar();
            procesarPagoPromise(paymentRequest).then(function (response) {
                Modal.hideLoadingBar();
                if (response.idPago <= 0) {
                    var errorMessage = response.description || 'Error desconocido.';
                    mostrarModalError('Error: ' + errorMessage);
                    return; // Evita continuar con la lógica de éxito
                }
                var gti = "GTI" + response.idPago;
                var smsLink = 'sms:+506' + vm.bankNumber + '?body=PASE ' + Math.round(charge.leftToPay) + ' ' + vm.bankNumber + ' ' + gti;
                mostrarModalExito(charge, gti);
                vm.showTable = false;

            }).catch(function (error) {
                Modal.hideLoadingBar();
                var errorMessage = error.data.description  || 'Ya se solicitó el mensaje de texto para el pago de esta factura';
                mostrarModalError('Error: ' + errorMessage);
            });
        }

        function procesarPagoPromise(paymentRequest) {
            return new Promise(function (resolve, reject) {
                Charge.processPay(paymentRequest,
                    function (response) {
                        resolve(response);
                    },
                    function (error) {
                        reject(error);
                    }
                );
            });
        }

        function mostrarModalExito(charge, gti) {
            // Usar traducción para el título del modal
            var modalTitle = translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.SUCCESS_MODAL_TITLE');

            // Preparar el contenido del SMS
            var smsBody = 'PASE ' + Math.round(charge.leftToPay) + ' '+vm.bankNumber+' ' +gti;
            var smsLink = 'sms:+506'+vm.bankNumber+'?body=' + encodeURIComponent(smsBody);

            // Mostrar el modal
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title(modalTitle) // Solo muestra el título
                    .ariaLabel(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.PAYMENT_CONFIRMATION'))
                    .ok(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.SUCCESS_MODAL_OK'))
            ).then(function () {
                // Acción al aceptar el modal: abrir el enlace de SMS
                window.open(smsLink, '_self');
            });
        }


        function mostrarModalError(error) {
            $mdDialog.show(
                $mdDialog.alert()
                    .clickOutsideToClose(true)
                    .title(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.PAYMENT_FAILURE_TITLE'))
                    .textContent("Error: " + (error.message || JSON.stringify(error)))
                    .ariaLabel(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.PAYMENT_FAILURE_TITLE'))
                    .ok(translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.SUCCESS_MODAL_OK'))
            );
        }

        function defineRole(role) {
            switch (role) {
            case "ROLE_OWNER":
                return "Residente propietario";
            case "ROLE_RENTER":
                return "Propietario arrendador";
            case "ROLE_TENANT":
                return "Inquilino";
            case "ROLE_RESIDENT":
                return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                case "ROLE_OWNER":
                    resident.houses[i].role = "Residente propietario";
                    break;
                case "ROLE_RENTER":
                    resident.houses[i].role = "Propietario arrendador";
                    break;
                case "ROLE_TENANT":
                    resident.houses[i].role = "Inquilino";
                    break;
                case "ROLE_RESIDENT":
                    resident.houses[i].role = "Residente autorizado";
                    break;
                }
            }
            return resident;
        };

        function formatResidentList(role, data, houseId) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == houseId) {
                        r.role = h.role;
                    }
                }
                if (r.role == role) {
                    r.roleDescription = defineRole(r.role);
                    r = vm.defineRoleUser(r);
                    list.push(r);
                }
            }
            return list;
        }

        vm.open = function (chargeId) {
            vm.checkedType = 3;
            vm.residents = [];
            vm.chargeSelectedId = chargeId;
            Resident.getResidentsByHouse({
                houseId: $localStorage.houseSelected.id
            }, function (data) {
                vm.owners = formatResidentList("ROLE_OWNER", data, $localStorage.houseSelected.id);
                vm.owners = vm.owners.concat(formatResidentList("ROLE_RENTER", data, $localStorage.houseSelected.id));
                vm.tenants = formatResidentList("ROLE_TENANT", data, $localStorage.houseSelected.id);
                vm.residents = formatResidentList("ROLE_RESIDENT", data, $localStorage.houseSelected.id);
                vm.residents.concat(vm.owners);
                vm.all = vm.owners.concat(vm.tenants);
                vm.all = vm.all.concat(vm.residents);
                $mdDialog.show({
                    templateUrl: 'app/entities/charge/charge-send-email-form.html',
                    scope: $scope,
                    preserveScope: true
                });
            }, onError);

            function onError() {
            }
        };


        vm.selectPrincipalContact = function () {
            angular.forEach(vm.residents, function (resident, i) {
                if (resident.principalContact == 1) {
                    resident.selected = true;
                }
            });
        };
        vm.selectAllContact = function () {
            angular.forEach(vm.residents, function (resident, i) {
                if (resident.email != null) {
                    resident.selected = true;
                } else {
                    resident.selected = false;
                }
            });
        };

        vm.selectTenant = function () {
            angular.forEach(vm.residents, function (resident, i) {
                if (resident.type == 4 && resident.email != null) {
                    resident.selected = true;
                } else {
                    resident.selected = false;
                }
            });
        };

        vm.close = function () {
            $mdDialog.hide();
        };


        vm.sendByEmail = function () {
            if (obtainEmailToList() != "") {
                Modal.showLoadingBar();
                var residentsToSendEmails = obtainEmailToList().slice(0, -1);
                Charge.sendChargeEmail({
                    companyId: globalCompany.getId(),
                    houseId: vm.chargeSelectedId.id,
                    emailTo: residentsToSendEmails
                }, function (result) {
                    $mdDialog.hide();
                    Modal.hideLoadingBar();
                    Modal.toast("Se envió la cuota por correo correctamente.");
                });
            } else {
                Modal.toast("Selecciona al menos una persona.");
            }
        };

        function obtainEmailToList() {
            var residentsToSendEmails = "";
            angular.forEach(vm.all, function (resident, i) {
                if (resident.selected == true) {
                    if (residentsToSendEmails.indexOf(resident) === -1) {
                        residentsToSendEmails = residentsToSendEmails + resident.id + ",";
                    }
                }
            });
            return residentsToSendEmails;
        }


        vm.deleteCharge = function (charge) {
            Modal.confirmDialog("¿Está seguro que desea eliminar la cuota " + charge.concept + "?", "Una vez eliminado no podrá recuperar los datos",
                function () {
                    Modal.showLoadingBar();
                    charge.deleted = 1;
                    Charge.update(charge, onSaveSuccess, onSaveError);

                    function onSaveSuccess(result) {
                        House.get({
                            id: result.houseId
                        }, onSuccess);

                        function onSuccess(house) {
                            Modal.hideLoadingBar();
                            Modal.toast("La cuota se ha eliminado correctamente.");
                            $rootScope.houseSelected = house;
                            $localStorage.houseSelected = house;
                            loadAll();
                            vm.isEditing = true;
                        }

                    }

                    function onSaveError() {

                    }
                });

        };
        vm.validCharges = function () {
            var invalido = 0;
            angular.forEach(vm.charges, function (val, index) {
                if (val.leftToPay == 0) {
                    invalido++;
                }
            });
            if (invalido == 0) {
                return true;
            } else {
                return false;
            }
        };
        vm.editing = function () {
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.isEditing = true;
                    $('.dating').keydown(function () {
                        return false;
                    });
                    angular.forEach(vm.charges, function (charge, i) {
                        charge.date = new Date(vm.charges[i].date);
                    });
                });
            }, 100);

        };
        vm.createCharge = function () {
            $state.go('houseAdministration.chargePerHouse.new');
        };
        vm.cancel = function () {
            $("#data").fadeOut(0);
            $("#loading").fadeIn("slow");
            loadAll();
            vm.isEditing = false;
        };
        $scope.$watch(function () {
            return $rootScope.houseSelected;
        }, function () {
            $("#data").fadeOut(0);
            $("#loading").fadeIn("slow");
            houseId = $localStorage.houseSelected.id;
            loadAll();
            vm.isEditing = false;
        });


        vm.getCategory = function (type) {
            if (parseFloat(type) < 9) {
                switch (type) {
                case "1":
                    return "MANTENIMIENTO";
                    break;
                case "2":
                    return "EXTRAORDINARIA";
                    break;
                case "3":
                    return "ÁREAS COMUNES";
                    break;
                case "5":
                    return "MULTA";
                    break;
                case "6":
                    return "CUOTA AGUA";
                    break;
                case "8":
                    return "INTERÉS";
                    break;
                }
            } else {
                for (var i = 0; i < vm.customChargeTypes.length; i++) {
                    var cc = vm.customChargeTypes[i];
                    if (parseFloat(type) == cc.type) {
                        return cc.description;
                    }
                }
            }
        };

        vm.openCalendar = function (charge) {
            charge.openDate = true;
        };

        function loadAll() {
            vm.isReady = false;
            CustomChargeType.getByCompany({companyId: globalCompany.getId()}, function (result) {
                vm.customChargeTypes = result;
                Charge.queryByHouse({
                    houseId: CommonMethods.encryptS(houseId),
                    type: vm.chargeFilter,
                    sort: sort()
                }, onSuccess, onError);

                function sort() {
                    var result = [];
                    if (vm.predicate !== 'date') {
                        result.push('date,desc');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    var countPassedDate = 0;
                    var rightNow = new Date();
                    angular.forEach(data, function (cuota, i) {
                        cuota.openDate = false;
                        cuota.type = cuota.type + "";
                        var chargeDate = new Date(moment(cuota.date));
                        if (chargeDate.getTime() > rightNow.getTime()) {
                            cuota.datePassed = true;
                            if (countPassedDate == 0) {
                                cuota.definedFirstDatePassed = true;
                                countPassedDate++;
                            }
                        }
                        cuota.temporalAmmount = cuota.ammount;
                    });
                    var charges = [];
                    angular.forEach(data, function (cuota, i) {
                        if (vm.authorities == "ROLE_TENANT") {
                            if (cuota.type == 3 || cuota.type == 6 || cuota.type > 8) {
                                charges.push(cuota);
                                console.log("A");
                            }
                        } else {
                            charges.push(cuota);
                        }
                    });
                    vm.charges = charges;
                    vm.page = pagingParams.page;
                    vm.isReady = true;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            });
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }


    }

})();
