(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('PaymentDetailController', PaymentDetailController);

    PaymentDetailController.$inject = ['JhiLanguageService','$translate','Banco', 'globalCompany', '$state', 'CommonMethods', '$scope', '$rootScope', '$stateParams', 'DataUtils', 'entity', 'Officer', 'User', 'Company', 'Principal', 'Modal', 'Resident', '$localStorage', 'Payment'];

    function PaymentDetailController(JhiLanguageService,translate, Banco, globalCompany, $state, CommonMethods, $scope, $rootScope, $stateParams, DataUtils, entity, Officer, User, Company, Principal, Modal, Resident, $localStorage, Payment) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.payment = entity;
        vm.isReady = true;
        vm.email = "";
        vm.exportActions = {
            downloading: false,
            printing: false,
            sendingEmail: false,
        };

        Banco.get({id:vm.payment.accountId}, function (data) {
            vm.paymentCurrency = data.accountingAccount.currency;
        });

        vm.detailPayment = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('payment-detail', {
                id: encryptedId
            });
        };

        JhiLanguageService.getCurrent().then(function (lang) {
            vm.currentLang = lang;
            $rootScope.currentLang = lang;
            moment.locale(vm.currentLang);
            if (vm.currentLang == "es") {
                vm.noContent = "No existen cuotas canceladas con este adelanto de condómino aún";
            } else {
                vm.noContent = "No news yet";
            }
        });

        vm.savePayment = function () {
            Modal.confirmDialog("¿Está seguro que desea aplicar los cambios?", "", function () {
                if(vm.account!=undefined){
                    vm.payment.account = vm.account.id;
                }
                Payment.update(vm.payment, function (data) {
                    Modal.toast("Listo.");
                    $rootScope.back();
                });
            });
        };
        $rootScope.mainTitle = translate.instant("aditumApp.financesResidentAccount.paymentsPerHouse.detailTitle");
        Modal.enteringDetail();
        $scope.$on("$destroy", function () {
            Modal.leavingDetail();
        });

        if (vm.payment.accountId != null) {
            Banco.query({
                companyId: globalCompany.getId()
            }, onSuccess, function () {
            });
        }

        function onSuccess(data, headers) {
            vm.bancos = data;
            for (var i = 0; i < vm.bancos.length; i++) {
                if (vm.payment.accountId == vm.bancos[i].id) {
                    vm.account = vm.bancos[i];
                }
            }
        }

        vm.detailProof = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('payment-proof-detail', {
                id: encryptedId
            });
        };
        vm.print = function (paymentId) {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                });
            }, 8000);
            printJS({
                printable: '/api/payments/file/' + paymentId,
                type: 'pdf',
                modalMessage: "Obteniendo comprobante de pago"
            });
        };


        vm.download = function () {
            vm.exportActions.downloading = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.downloading = false;
                });
            }, 8000);
        };

        vm.sendEmail2 = function (payment) {
            if (vm.email != undefined || vm.email != "") {
                Modal.confirmDialog("¿Está seguro que desea enviarle el comprobante del pago " + payment.receiptNumber + " a " + vm.email + "?", "",
                    function () {
                        vm.exportActions.sendingEmail = true;
                        Payment.sendPaymentEmail({
                            paymentId: payment.id,
                            email: vm.email
                        });
                        setTimeout(function () {
                            $scope.$apply(function () {
                                vm.exportActions.sendingEmail = false;
                            });
                            Modal.toast("Se ha enviado el comprobante por correo al contacto principal.");
                        }, 8000);
                    });
            } else {
                Modal.toast("Debe de ingresar un email.");
            }
        };


        vm.sendEmail = function (payment) {

            Modal.confirmDialog("¿Está seguro que desea enviarle el comprobante del pago " + payment.receiptNumber + " al contacto principal de la filial " + $localStorage.houseSelected.housenumber + "?", "",
                function () {
                    vm.exportActions.sendingEmail = true;
                    var thereIs = $localStorage.houseSelected.principalContact!=null;
                    if (thereIs === true) {
                        Payment.sendPaymentEmail({
                            paymentId: payment.id
                        });
                        setTimeout(function () {
                            $scope.$apply(function () {
                                vm.exportActions.sendingEmail = false;
                            });
                            Modal.toast("Se ha enviado el comprobante por correo al contacto principal.");
                        }, 8000);
                    } else {
                        vm.exportActions.sendingEmail = false;
                        Modal.toast("Esta filial no tiene un contacto principal para enviarle el correo.");
                    }

                    function onError() {
                        Modal.toast("Esta filial no tiene un contacto principal para enviarle el correo.");
                    }
                });
        };


        vm.currencyDoubleMoney = function(){
            if($rootScope.currency=="$"){
                return "₡";
            }else{
                return "$";
            }
        };

        vm.getCategory = function (type) {
            switch (type) {
            case 1:
                return "MANTENIMIENTO";
                break;
            case 2:
                return "EXTRAORDINARIA";
                break;
            case 3:
                return "ÁREAS COMUNES";
                break;
            case 5:
                return "MULTA";
                break;
            case 6:
                return "CUOTA AGUA";
                break;
            }
        };
    }
})();
