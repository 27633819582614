(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('UnidentifiedPaymentController', UnidentifiedPaymentController);

    UnidentifiedPaymentController.$inject = ['AlertService', 'Banco', '$localStorage', '$state', 'Modal', 'UnidentifiedPayment', '$rootScope', 'globalCompany', 'CommonMethods'];

    function UnidentifiedPaymentController(AlertService, Banco, $localStorage, $state, Modal, UnidentifiedPayment, $rootScope, globalCompany, CommonMethods) {
        $rootScope.active = "unidentified-payment";
        var vm = this;
        vm.isIdentified = false;
        vm.payments = [];
        vm.isReady = false;
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);

        function loadBancos() {
            Banco.query({
                companyId: globalCompany.getId()
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.bancos = data;
                vm.loadAll();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadBancos();

        if (!$localStorage.datesUnidentifiedPayments) {
            $localStorage.datesUnidentifiedPayments = {
                initial_time: firstDay,
                final_time: lastDay
            };
        }

        vm.dates = $localStorage.datesUnidentifiedPayments;

        vm.loadAll = function () {
            vm.payments = [];
            vm.isReady = false;
            UnidentifiedPayment.getByCompany({
                companyId: globalCompany.getId(),
                initialTime: moment(vm.dates.initial_time).format(),
                finalTime: moment(vm.dates.final_time).format(),
                isIdentified: vm.isIdentified
            }, function (result) {
                vm.isReady = true;
                for (var i = 0; i < result.length; i++) {
                    for (var j = 0; j < vm.bancos.length; j++) {
                        if(result[i].account == vm.bancos[j].beneficiario) {
                            result[i].currency = vm.bancos[j].accountingAccount.currency;
                        }
                    }
                }
                vm.payments = result;
            });
        };

        vm.deletePayment = function (id) {
            var idEncrypted = CommonMethods.encryptIdUrl(id);
            $state.go("unidentified-payment.delete", {paymentId: idEncrypted});
        };

        vm.detailPayment = function (id) {
            var idEncrypted = CommonMethods.encryptIdUrl(id);
            $state.go('payment-detail', {
                id: idEncrypted
            });
        };

        vm.identifyPayment = function (id) {
            var idEncrypted = CommonMethods.encryptIdUrl(id);
            $state.go("identify-payment", {paymentId: idEncrypted});
        };
        vm.loadAll();
    }
})();
