(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('unidentified-payment.delete', {
                parent: 'unidentified-payment',
                url: '/delete-payment/{paymentId}',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl:'app/entities/payment/payment-delete-dialog.html',
                        controller: 'PaymentDeleteController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    concept: null,
                                    amount: null,
                                    date: null,
                                    reason: null,
                                    deleted: null,
                                    originalBillDate: null,
                                    referenceNumber: null,
                                    numericKey: null,
                                    electronicBillStatus: null,
                                    electronicBillResponse: null,
                                    isElectronicBillSend: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('unidentified-payment', null, {reload: 'unidentified-payment'});
                    }, function () {
                        $state.go('unidentified-payment');
                    });
                }]
            })
            .state('identify-payment', {
                parent: 'entity',
                url: '/identify-payment/{paymentId}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/unidentified-payment/identify-payment.html',
                        controller: 'IdentifyPaymentController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('unidentified-payment', {
                parent: 'entity',
                url: '/unidentified-payment',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/unidentified-payment/unidentified-payment.html',
                        controller: 'UnidentifiedPaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('rHAccount');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
