(function() {
    'use strict';
    angular
        .module('aditumApp')
        .factory('UnidentifiedPayment', UnidentifiedPayment);

    UnidentifiedPayment.$inject = ['$resource'];

    function UnidentifiedPayment ($resource) {
        var resourceUrl =  'api/payment/:id';

        return $resource(resourceUrl, {  }, {
            'getByCompany': { method: 'GET',   url:'api/payments/unidentified/:companyId/:initialTime/:finalTime/:isIdentified', isArray: true},
        });
    }
})();
