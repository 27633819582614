(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('HouseController', HouseController);

    HouseController.$inject = ['$mdDialog', '$scope', '$state', 'House', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Principal', '$rootScope', 'CommonMethods', 'Modal', 'globalCompany', 'AdministrationConfiguration', '$filter'];

    function HouseController($mdDialog, $scope, $state, House, ParseLinks, AlertService, paginationConstants, pagingParams, Principal, $rootScope, CommonMethods, Modal, globalCompany, AdministrationConfiguration,$filter) {
        $rootScope.active = "houses";
        var vm = this;
        $rootScope.mainTitle = "Filiales";
        vm.isReady = false;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.estado = "";
        vm.ocultarACondos = true;
        vm.estadoTemporal = "2";
        vm.filter = {
            houseNumber: " ",
            desocupated: "empty"
        };
        vm.itemsPerPage = 12;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.housesDesorder = [];
        vm.open = function (ev) {
            $mdDialog.show({
                templateUrl: 'app/entities/house/houses-filter.html',
                scope: $scope,
                preserveScope: true,
                targetEvent: ev
            });
        };

        vm.close = function () {
            $mdDialog.hide();
        };
        vm.closeAndFilter = function () {
            vm.filterHouses();
            $mdDialog.hide();
        };
        vm.filterHouses = function () {
            vm.isReady = false;
            vm.page = 0;
            vm.links = {
                last: 0
            };
            vm.houses = [];
            vm.housesDesorder = [];
            loadAll();
        };

        vm.enabledOptions = true;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.houses = [];
        vm.housesDesorder = [];


        vm.setEstadoView = function (val) {
            if (val == 2) {
                vm.estado = "";
            } else {
                vm.estado = val;
            }

        };
        loadAll();


        vm.editHouse = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('houses-tabs.edit', {
                id: encryptedId
            });
        };

        function loadAll() {
            if(vm.filter.houseNumber==""||vm.filter.houseNumber==undefined){
                vm.filter.houseNumber = " ";
            }
            House.getAllHouses({
                page: vm.page,
                size: 77,
                desocupated : vm.filter.desocupated,
                houseNumber: vm.filter.houseNumber,
                sort:sort(),
                companyId: globalCompany.getId()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + ('desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                angular.forEach(data, function (value, key) {
                    vm.housesDesorder.push(value);
                });
                vm.housesDesorder.sort(function(a, b){ a.housenumber.localeCompare(b.housenumber, undefined, { numeric: true, sensitivity: 'base' });});
                vm.houses = vm.housesDesorder;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function loadPage(page) {
            vm.page = page;
            loadAll();
        }
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }


        vm.showKeys = function (house_number, securityKey) {
            if (securityKey == null  || securityKey == "") {
                Modal.toast("Esta filial aún no tiene clave de seguridad asignada.");
            } else {
                bootbox.dialog({
                    message: '<div class="text-center gray-font font-20"> <h1 class="font-30">Filial número <span class="font-30" id="key_id_house"></span></h1></div> <div class="text-center gray-font font-20"> <h1 class="font-20">Clave de seguridad: <span class="font-20 bold" id="security_key">{{securityKey}}</span></h1></div> <div class="text-center gray-font font-20"></div>',
                    closeButton: false,
                    buttons: {
                        confirm: {
                            label: 'Ocultar',
                            className: 'btn-success'
                        }
                    },
                });
                document.getElementById("key_id_house").innerHTML = "" + house_number;
                document.getElementById("security_key").innerHTML = "" + securityKey;
            }
        };
        vm.showLoginCode = function (house_number, codeStatus, loginCode) {
            var estado = "";
            if (loginCode == null) {
                Modal.toast("Esta filial aún no tiene claves de seguridad asignadas.");
            } else {

                if (codeStatus == false || codeStatus == 0) {
                    estado = 'No activada';
                } else {
                    estado = "Activada";
                }
                bootbox.dialog({
                    message: '<div class="text-center gray-font font-20"> <h1 class="font-30">Filial número <span class="font-30" id="key_id_house"></span></h1></div> <div class="text-center gray-font font-15"> <h1 class="font-20">Código de iniciación: <span class="font-15 bold" id="login_code">1134314</span></h1></div> <div class="text-center gray-font font-15"> <h1 class="font-20">Estado: <span class="font-15 bold" id="code_status">1134314</span></h1></div>',
                    closeButton: false,
                    buttons: {
                        confirm: {
                            label: 'Ocultar',
                            className: 'btn-success'
                        }
                    },
                });

                document.getElementById("key_id_house").innerHTML = "" + house_number;
                document.getElementById("login_code").innerHTML = "" + loginCode;
                document.getElementById("code_status").innerHTML = "" + estado;
            }
        };
        vm.viewDetail = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('houses-tabs.detail', {
                id: encryptedId
            });
        };

        vm.tableToExcel = function (tableHtml) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;

            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)));
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                });
                            };

                        var workSheetName = "Filiales";
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: tableHtml};

                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx));
                        a.download = workSheetName + '.xlsx';
                        a.click();

                        vm.exportingExcel = false;
                    });
                }, 1);
                vm.notExportingExcel = true;
            }, 500);
        };

        vm.downloadExcel = function () {
            House.getAllHouses({
                size: 99999,
                desocupated: vm.filter.desocupated,
                houseNumber: vm.filter.houseNumber,
                companyId: globalCompany.getId()
            }).$promise.then(function (result) {
                var allHouses = result;

                var XLSX = window.XLSX || {};

                if (!XLSX.utils) {
                    AlertService.error("Error: XLSX no está correctamente cargado.");
                    return;
                }

                var headers = [
                    { Filial: "Filial", Cuota: "Cuota", "Área metros cuadrados": "Área metros cuadrados",
                        "Número de folio": "Número de folio", Estado: "Estado",
                        Habitada: "Habitada", "En alquiler": "En alquiler", "Se le cobra agua": "Se le cobra agua" }
                ];

                var data = [];
                for (var i = 0; i < allHouses.length; i++) {
                    var house = allHouses[i];
                    data.push({
                        Filial: house.housenumber,
                        Cuota: house.due ? house.due : 'No tiene',
                        "Área metros cuadrados": house.squareMeters,
                        "Número de folio": house.folioNumber,
                        Estado: house.status,
                        Habitada: house.isHabited ? 'Habitada' : 'Deshabitada',
                        "En alquiler": house.isRented ? 'Alquilada' : 'No alquilada',
                        "Se le cobra agua": house.chargeWaterFee ? 'Sí' : 'No'
                    });
                }

                var ws = XLSX.utils.json_to_sheet(data);

                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Filiales");

                var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                var blob = new Blob([wbout], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

                var url = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = "filiales.xlsx";  // Nombre del archivo a descargar
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url);

                AlertService.success("El archivo Excel ha sido descargado.");
            }).catch(function (error) {
                AlertService.error("Error al obtener las casas.");
            });
        };

        vm.resetHouseWithConfirmations = function resetHouseWithConfirmations(houseId) {
            var wordOnModal = "resetear información de";
            function confirmAndReset() {
                if (houseId !== null) {
                    Modal.showLoadingBar();
                    House.resetHouse({
                        companyId: globalCompany.getId(),
                        houseId: houseId
                    }).$promise.then(function () {
                        Modal.hideLoadingBar();
                        Modal.toast("La información de la casa se ha reseteado con éxito.");
                        $scope.successMessage = "La información de la casa se ha reseteado con éxito.";
                    }, function (error) {
                        Modal.hideLoadingBar();
                        Modal.toast("Hubo un error al resetear la información de la casa.");
                        $scope.errorMessage = "Hubo un error al resetear la información de la casa.";
                        console.error('Error resetting house:', error);
                    });
                }
            }

            function confirm3() {
                Modal.confirmDialog("¿Está seguro que desea " + wordOnModal + " la casa? (Última confirmación)", "No podrá recuperar ninguna de la información eliminada", confirmAndReset);
            }

            function confirm2() {
                Modal.confirmDialog("¿Está realmente seguro que desea " + wordOnModal + " la casa?", "No podrá recuperar ninguna de la información eliminada", confirm3);
            }

            function confirm1() {
                Modal.confirmDialog("¿Está seguro que desea " + wordOnModal + " la casa?", "Se borrarán visitantes invitados y Visitas", confirm2);
            }

            confirm1();
        };
    }
})();
