(function () {
    'use strict';

    angular
            .module('aditumApp')
            .controller('LoadAutomaticPaymentBanksController', LoadAutomaticPaymentBanksController);

    LoadAutomaticPaymentBanksController.$inject = [
        'ExchangeRateBccr',
        'AdministrationConfiguration',
        'CommonMethods',
        '$localStorage',
        '$mdDialog',
        '$scope',
        '$state',
        'House',
        'globalCompany',
        '$rootScope',
        'DataUtils',
        'Modal',
        'Payment',
        'Banco',
        'Resident' // Agregando el servicio de Resident
    ];

    function LoadAutomaticPaymentBanksController(ExchangeRateBccr, AdministrationConfiguration, CommonMethods, $localStorage, $mdDialog, $scope, $state, House, globalCompany, $rootScope, DataUtils, Modal, Payment, Banco, Resident) {
        $rootScope.active = "load-automatic-payment-banks";
        var vm = this;
        var file;
        vm.fileName;
        vm.chargesList = [];
        vm.isReady = 0;
        vm.bccrUse = true;
        vm.today = new Date();
        moment().locale("es");
        vm.applyType = "byDate";
        vm.proccesing = false;
        vm.totalPayment = 0;
        vm.showReferences = false;
        vm.filterReferences = undefined;


        vm.deletePayment = function (payment, num) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el pago #" + num + " de la lista?", "", function () {
                CommonMethods.deleteFromArray(payment, vm.paymentList);
            });
        };

        vm.clearSearchTerm = function (searchTerm) {
            searchTerm = '';
        };
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        };

        vm.filterAlreadyPayed = function () {

            var filterPayments = { companyId: globalCompany.getId(), paymentList: vm.paymentList };

            Payment.filterAlreadyPayed(filterPayments, function (response) {

                vm.duplicatedReferences = response.duplicatedReferences.split(",");
                vm.paymentList = response.paymentList;
                vm.isReady = 2;
                vm.proccesing = false;

            }, function (error) {
            });
        };


            // Lista inicial de cuotas con su respectivo valor
        vm.chargesType = [
                {name: 'Mantenimiento', value: 1},
                {name: 'Cuotas agua', value: 6},
                {name: 'Extraordinarias', value: 2},
                {name: 'Multa', value: 5},
                {name: 'Interés', value: 8},
                {name: 'Áreas comunes', value: 4},
                {name: 'Otros', value: 9}
        ];


            // Función para mover una cuota hacia arriba
        vm.moverArriba = function (index) {
            if (index > 0) {
                var temp = vm.chargesType[index];
                vm.chargesType[index] = vm.chargesType[index - 1];
                vm.chargesType[index - 1] = temp;
            }
        };

            // Función para mover una cuota hacia abajo
        vm.moverAbajo = function (index) {
            if (index < vm.chargesType.length - 1) {
                var temp = vm.chargesType[index];
                vm.chargesType[index] = vm.chargesType[index + 1];
                vm.chargesType[index + 1] = temp;
            }
        };

        vm.positiveBalances = ["MANT", "AGUA", "AREAS", "MULT", "EXTRA"];

        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());

        vm.secondCurrency = vm.companyConfig.secondCurrency;
        vm.principalCurrency = vm.companyConfig.currency;
        vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
        vm.exchangeRateCurrency = CommonMethods.getExchangeRateCurrency(vm.currencies);

        vm.showBccrUse = function () {
            var currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
            if (currencies.length !== 2) {
                return false;
            }
            var showBccrUse = false;
            var hasColon = false;
            var hasDollar = false;
            for (var i = 0; i < currencies.length; i++) {
                if (currencies[i].symbol === "₡") {
                    hasColon = true;
                }
                if (currencies[i].symbol === "$") {
                    hasDollar = true;
                }
                if (hasColon && hasDollar) {
                    showBccrUse = true;
                    break; // Sale del ciclo si ya encontró ambas monedas
                }
            }
            return showBccrUse;
        };

        vm.saveAdminConfig = function () {
            vm.adminConfig.exchangeRateDate = moment().format();
            if (vm.adminConfig.id !== null) {
                AdministrationConfiguration.update(vm.adminConfig, function (result) {
                }, function () {
                });
            } else {
                AdministrationConfiguration.save(vm.adminConfig, function () {
                }, function () {
                });
            }
        };

        AdministrationConfiguration.get({
            companyId: globalCompany.getId()
        }).$promise.then(function (result) {
            vm.adminConfig = result;
            vm.adminConfig.exchangeRate = parseFloat(vm.adminConfig.exchangeRate);
            if (vm.showBccrUse()) {
                ExchangeRateBccr.get({
                    fechaInicio: moment(new Date()).format(),
                    fechaFinal: moment(new Date()).format(),
                }, function (result) {
                    vm.tipoCambio = result;
                });
            }
        });

        if ($localStorage.houses) {
            vm.houses = $localStorage.houses;
        } else {
            House.getAllHousesClean({
                companyId: globalCompany.getId()
            }, function (result) {
                vm.houses = result;
                if (!vm.payment) {
                    vm.payment = {};
                }
                vm.payment.houses = angular.copy(vm.houses);
            });
        }

        vm.payment = vm.payment || {};
        vm.payment.houses = angular.copy(vm.houses);


        vm.creatingCharges = false;
        vm.chargeCount = 0;

        function loadBancos() {
            Banco.query({
                companyId: globalCompany.getId()
            }, function (data) {
                vm.bancos = data;
                if (vm.paymentList && vm.paymentList.length > 0) {
                    $scope.$apply(function () {
                        vm.isReady = 2;
                    });
                }
            });
        }

        loadBancos();

        vm.searchTerm = '';

        vm.updateSearchTerm = function (searchTerm) {
            vm.searchTerm = searchTerm;
        };
        vm.searchTerm;
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        };

        vm.updateFilter = function (payment) {
            $timeout(function () {
                var input = document.querySelector('.demo-header-searchbox');
                if (input) {
                    input.focus();
                }
            }, 100);
        };

        vm.clearSearchTermOwner = function () {
            vm.searchTermOwner = '';
        };

        vm.changeHouse = function (houseId) {
            vm.isReady = false;
            House.getToGeneratePayment({
                id: houseId
            }, function (result) {
                clear();
                $localStorage.houseSelected = result;
                $rootScope.houseSelected = result;
                vm.house = result;
                vm.houseId = vm.house.id;
                formatPaymentCharges(vm.house.chargesToPay);
                vm.useSaldoFavor = false;
                vm.keepShowingForm = true;
                vm.totalToUseUsed = 0;
                vm.charges = [];
                vm.totalToUse = 0;
                vm.selectedSaldo = {amount: 0};
                vm.hasNotes = vm.house.hasAccountingNotes;
                vm.residents = formatResidentList(vm.house.contacts, houseId);
                vm.paymentProofs = vm.house.paymentProofs;
                loadAdminConfig();
                if (vm.bancos.length < 3) {
                    if (globalCompany.getId() == 94) {
                        vm.account = vm.bancos[0];
                    } else {
                        vm.account = vm.bancos[1];
                    }
                    if (vm.bancos.length == 1) {
                        vm.account = vm.bancos[0];
                    }
                }
                if ($rootScope.paymentProofData != undefined) {
                    if ($rootScope.paymentProofData.bank) {
                        for (var i = 0; i < vm.bancos.length; i++) {
                            if (vm.bancos[i].beneficiario == $rootScope.paymentProofData.bank) {
                                vm.account = vm.bancos[i];
                            }
                        }
                    }
                    if ($rootScope.paymentProofData.reference) {
                        vm.payment.documentReference = $rootScope.paymentProofData.reference;
                    }
                }
                vm.useSaldo.maintenance = false;
                vm.useSaldo.multas = false;
                vm.useSaldo.others = false;
                vm.useSaldo.waterCharges = false;
                vm.useSaldo.extraordinary = false;
            });

        };
        vm.chipHouse = function (house) {
            for (var i = 0; i < vm.ownersFilial.length; i++) {
                for (var j = 0; j < vm.ownersFilial[i].houses.length; j++) {
                    vm.ownersFilial[i].houses[j].selected = false;
                }
            }

            house.selected = true;

            vm.houseId = house.id;
            vm.searchTermFilial = house.housenumber;  // Actualizamos el término de búsqueda
        };

        vm.loadOwnersFilial = function (payment) {
            if (!payment.filterName || payment.filterName.trim() === "") {
                payment.ownersFilial = [];
                return;
            }
            Resident.getResidentsByFilter({
                companyId: globalCompany.getId(),
                filter: payment.filterName
            }, function (data) {
                payment.ownersFilial = data;
                var hasHouses = false;
                for (var i = 0; i < payment.ownersFilial.length; i++) {
                    if (payment.ownersFilial[i].houses && payment.ownersFilial[i].houses.length > 0) {
                        hasHouses = true;
                        break; // Salir del bucle al encontrar al menos una casa
                    }
                }
                if (hasHouses) {
                    var houses = [];
                        // Concatena todas las casas en un solo array
                    for (var j = 0; j < payment.ownersFilial.length; j++) {
                        if (payment.ownersFilial[j].houses && payment.ownersFilial[j].houses.length > 0) {
                            houses = houses.concat(payment.ownersFilial[j].houses);
                        }
                    }
                    payment.houses = houses; // Asigna el array resultante
                }
            });
        };


        vm.selectHouse = function (house, payment) {
            var owner = null;
            payment.houses = angular.copy(vm.houses);
            for (var i = 0; i < payment.ownersFilial.length; i++) {
                var houses = payment.ownersFilial[i].houses;
                for (var j = 0; j < houses.length; j++) {
                    if (houses[j].id === house.id) {
                        owner = payment.ownersFilial[i];
                        break;
                    }
                }
                if (owner) break;
            }
            if (owner) {
                payment.houses = angular.copy(owner.houses);
            }
            for (var i = 0; i < payment.houses.length; i++) {
                payment.houses[i].selected = false;
            }
            house.selected = true;
            payment.selectedHouse = house.id;
            payment.houses = angular.copy(vm.houses);
            payment.searchTermFilial = house.housenumber;
        };

        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        };

        vm.uploadFile = function (file) {
            if (!file) {
                return;
            }

            var fileExtension = file.name.split('.').pop().toLowerCase();
            vm.duplicatedReferences = null;
            vm.paymentList = [];
            if (fileExtension === 'xls' || fileExtension === 'xlsx') {
                vm.proccesing = true;
                processExcelFile(file);
            } else if (fileExtension === 'csv') {
                vm.proccesing = true;
                processCSVFile(file);
            } else {
                vm.proccesing = false;
                alert('Formato de archivo no soportado. Por favor, suba un archivo válido');
            }
        };

        function processExcelFile(file) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;
                try {
                    var workbook = XLSX.read(data, { type: 'binary' });

                    // Verificar si el Excel tiene hojas
                    if (!workbook.SheetNames || workbook.SheetNames.length === 0) {
                        alert("El archivo Excel no tiene hojas activas. Verifique el formato.");
                        return;
                    }

                    var sheetName = workbook.SheetNames[0];
                    var sheet = workbook.Sheets[sheetName];
                    var sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });


                   if (!sheetData[0] || sheetData[0].length < 3) {
                       sheetData[0] = sheetData[0] || [];
                       sheetData[0][2] = "";
                   }

                   var identifierCell = sheetData[0][2] ? sheetData[0][2].toString() : "";

                   if (identifierCell.includes("Cliente")) {
                       processExcelType2(sheetData);
                   } else {
                       processExcelType1(sheetData);
                   }
                } catch (error) {
                    alert("Hubo un error al procesar el archivo Excel. Verifique el formato.");
                }
            };

            reader.onerror = function () {
                alert("Error al leer el archivo Excel.");
            };

            reader.readAsBinaryString(file);
        }

        function processExcelType1(sheetData) {
            var headers = sheetData[13];
            var filteredData = sheetData.slice(14);
            var dataToProcess = [];

            for (var i = 0; i < filteredData.length; i++) {
                var row = filteredData[i];
                if (!row || row.length === 0 || row.every(function (cell) { return cell === null || cell === undefined || cell === ''; })) {
                    continue;
                }
                if (row.some(function (cell) { return cell && cell.toString().indexOf('Cuadro de Resumen') !== -1; })) {
                    break;
                }
                dataToProcess.push(row);
            }

            var datosProcesados = dataToProcess
                .filter(function (row) { return Array.isArray(row) && row.length > 1 && row[0] !== "Cuadro de Resumen"; })
                .map(function (row) {
                    return {
                        date: row[0] ? parseExcelDate(row[0]) : "",
                        reference: row[1] || "",
                        comments: row[4] || "",
                        ammount: row[8] ? parseFloat(String(row[8]).replace(",", "")) : 0
                    };
                })
                .filter(function (item) { return item.ammount > 0; });

            vm.paymentList = datosProcesados.map(function (payment) {
                return Object.assign({}, payment, {
                    method: payment.method || "Transferencia",
                    ownersFilial: [],
                    houses: [],
                    filterName: "",
                    selectedHouse: null,
                    searchTermFilial: "",
                    applyPositiveBalance: "MANT"
                });
            });

            vm.filterAlreadyPayed();
        }

        function processExcelType2(sheetData) {
            if (!Array.isArray(sheetData) || sheetData.length === 0) {
                return;
            }

            var filteredData = sheetData.slice(7);
            var dataToProcess = [];

            for (var i = 0; i < filteredData.length; i++) {
                var row = filteredData[i];

                if (!Array.isArray(row) || row.length < 8) {
                    continue;
                }

                row = row.map(function(cell) {
                    return cell !== null && cell !== undefined ? String(cell).trim() : "";
                });

                if (row.some(function(cell) { return typeof cell === "string" && cell.indexOf("Total Débitos") !== -1; })) {
                    break;
                }

                dataToProcess.push(row);
            }

            if (dataToProcess.length === 0) {
                return;
            }

            function normalizeNumber(value) {

                if (value === '' || value === '-') {
                    value = 0;
                }

                value = value.replace(',', '.');
                return parseFloat(value.toString()) / 100;
            }

         function parseExcelDate(date) {
             if (typeof date === 'string' && date.indexOf('.') !== -1) {

                 var numberDate = parseFloat(date);

                 if (!isNaN(numberDate)) {
                     var baseDate = new Date(1899, 11, 30);
                     var milliseconds = numberDate * 24 * 60 * 60 * 1000;
                     var parsedDate = new Date(baseDate.getTime() + milliseconds);
                     var day = parsedDate.getDate();
                     var month = parsedDate.getMonth();
                     var year = parsedDate.getFullYear();
                     var invertedDate = new Date(year, day - 1, month + 1);

                     return invertedDate.toISOString();
                 }
             } else if (typeof date === 'string' && date.indexOf('/') !== -1) {
                 const parts = date.split('/');
                 if (parts.length === 3) {
                     const day = parseInt(parts[0], 10);
                     const month = parseInt(parts[1], 10) - 1;
                     const year = parseInt(parts[2], 10);
                     const parsedDate = new Date(year, month, day);

                     if (!isNaN(parsedDate.getTime())) {
                         return parsedDate.toISOString();
                     }
                 }
             }
             return '';
         }

            var datosProcesados = dataToProcess.map(function(row) {
                try {
                    var amountCell = row[7] ? row[7].trim() : "";
                    var amountValue = normalizeNumber(amountCell);

                    return {
                        date: row[0] ? parseExcelDate(row[0]) : "",
                        reference: row[3] || "",
                        comments: row[4] || "",
                        ammount: amountValue
                    };
                } catch (error) {
                    return null;
                }
            }).filter(function(item) { return item !== null && item.ammount !== null; });

            if (datosProcesados.length === 0) {
                return;
            }

            vm.paymentList = datosProcesados.map(function(payment) {

                var paymentItem = {
                    date: payment.date,
                    reference: payment.reference,
                    comments: payment.comments,
                    ammount: payment.ammount,
                    method: "Transferencia",
                    ownersFilial: [],
                    houses: [],
                    filterName: "",
                    selectedHouse: null,
                    searchTermFilial: "",
                    applyPositiveBalance: "MANT"
                };

                return paymentItem;
            });

            vm.filterAlreadyPayed();
        }

        function processCSVFile(file) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var data = e.target.result;

                try {
                    var rows = data.split('\n');
                    var headers = rows[0].split(';');
                    var dataToProcess = rows.slice(1).map(function (row) {
                        return row.split(';');
                    });

                    var datosProcesados = dataToProcess
                            .filter(function (row) {
                                return row.length > 1 && row[1];
                            })
                            .map(function (row) {
                                return {
                                    date: row[1] ? parseCsvDate(row[1]) : "",
                                    reference: row[2], // numeroDocumento
                                    ammount: parseFloat(row[4] ? row[4].replace(",", "") : "") || 0,
                                    comments: row[5] // descripcion
                                };
                            })
                            .filter(function (item) {
                                return item.ammount > 0;
                            });

                    vm.paymentList = [];
                    for (var i = 0; i < datosProcesados.length; i++) {
                        var payment = datosProcesados[i];
                        var newPayment = Object.assign({}, payment, {
                            method: "Transferencia",
                            ownersFilial: [],
                            houses: [],
                            filterName: "",
                            selectedHouse: null,
                            searchTermFilial: "",
                            applyPositiveBalance: "MANT",
                        });
                        vm.paymentList.push(newPayment);
                    }
                    vm.filterAlreadyPayed();
                } catch (error) {
                    alert('Hubo un error al procesar el archivo CSV. Verifique el formato.');
                }
            };

            reader.onerror = function () {
                alert('Error al leer el archivo CSV.');
            };

            reader.readAsText(file, 'UTF-8');
        }


        function parseCsvDate(dateStr) {
            var parts = dateStr.split('/');
            if (parts.length === 3) {
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1], 10) - 1;  // Meses 0-indexados
                var year = parseInt(parts[2], 10);
                // Si el año es de dos dígitos, conviértelo a cuatro dígitos
                if (year < 100) {
                    year += 2000;  // Asumimos que es del siglo XXI
                }
                return new Date(year, month, day);
            }
            return null;
        }

        function parseExcelDate(dateStr) {
            var parts = dateStr.split('/');
            if (parts.length === 3) {
                var day = parseInt(parts[0], 10);
                var month = parseInt(parts[1], 10) - 1;  // Los meses en JavaScript son 0-indexados
                var year = parseInt(parts[2], 10);
                return new Date(year, month, day);
            }
            return null;
        }

        vm.setHouses = function () {
            if (vm.chargesList.length > 0) {
                for (var i = 0; i < vm.chargesList.length; i++) {
                    vm.chargesList[i].houseId = vm.houseId;
                }

            }
        };

        vm.saveCharges = function () {
            if (!vm.selectedBank) {
                Modal.toast("Debe seleccionar un banco antes de registrar los pagos.");
                return;
            }

            Modal.confirmDialog("¿Está seguro que desea registrar los pagos?", "", function () {
                Modal.showLoadingBar();
                var formattedPayments = formatPayments(vm.paymentList);
                if (formattedPayments.length === 0) {
                    return;
                }
                vm.chargePriority = function () {
                    if (vm.applyType == 'byPriority') {
                        var values = [];
                        for (var i = 0; i < vm.chargesType.length; i++) {
                            values.push(vm.chargesType[i].value);
                        }
                        return values.join(',');
                    }
                    return null;
                };

                vm.error = false;
                Modal.showLoadingBar();
                vm.creatingCharges = true;
                Modal.toastGiant("Se están registrando los pagos, por favor espere y no cierre la ventana...");
                vm.formattedPayments = formattedPayments;
                createPayment(vm.formattedPayments[0], 0, vm.formattedPayments.length);
                if (vm.error) {
                    Modal.toast("Se ha presentado un error.");
                }
            });
        };

        function createPayment(payment, count, length) {
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.chargeCount = count;
                });
            }, 10);

            if (count < length) {
                payment.account = vm.selectedBank.beneficiario + ";" + vm.selectedBank.id;
                payment.currency = vm.selectedBank.accountingAccount.currency;
                payment.orderPriority = vm.chargePriority();
                var venta = vm.bccrUse
                        ? parseFloat((vm.tipoCambio && vm.tipoCambio.venta) ? vm.tipoCambio.venta : 0)
                        : parseFloat((vm.adminConfig && vm.adminConfig.exchangeRate) ? vm.adminConfig.exchangeRate : 0);

                payment.exchangeRate = venta;
                Payment.saveByTemplate(payment, function (result) {
                    count++;
                    if (count === length) {
                        Modal.hideLoadingBar();
                        vm.isReady = 0;
                        vm.creatingCharges = false;
                        Modal.toast("Se han registrado todos los pagos correctamente.");
                        vm.loadReferences();
                    } else {
                        createPayment(vm.formattedPayments[count], count, vm.formattedPayments.length);
                    }
                }, function (error) {
                    Modal.hideLoadingBar();
                    vm.creatingCharges = false;
                    Modal.actionToastGiantStay(
                            "Ocurrió un error en la creación de los pagos después del pago " + (parseInt(count) + 1)
                        );
                    vm.error = true;
                });
            }
        }

        function defineBanco(bancoId) {
            for (var i = 0; i < vm.bancos.length; i++) {
                if (bancoId == vm.bancos[i].id) {
                    return vm.bancos[i].beneficiario + ";" + vm.bancos[i].id;
                }
            }
        }

        vm.defineCurrency = function (bancoId) {
            for (var i = 0; i < vm.bancos.length; i++) {
                if (bancoId == vm.bancos[i].id) {
                    return vm.bancos[i].accountingAccount.currency;
                }
            }
        };

        vm.changeDateString = function (payment) {
            var dateStringF = new Date(payment.date);
            payment.dateString = moment(dateStringF).format('DD MMMM YYYY');
        };

        function formatPayments(payments) {
            var formatedPayments = [];
            var banco = vm.selectedBank;

            if (!banco) {
                return [];
            }

            for (var i = 0; i < payments.length; i++) {
                vm.readingCount = i;
                var payment = payments[i];
                var formatedPayment = {};
                formatedPayment.method = "Transferencia";
                formatedPayment.ammount = payment.ammount;
                formatedPayment.companyId = globalCompany.getId();
                formatedPayment.banco = banco;
                formatedPayment.currency = vm.defineCurrency(banco);
                formatedPayment.reference = payment.reference;
                formatedPayment.applyPositiveBalance = payment.applyPositiveBalance;
                formatedPayment.houseId = payment.selectedHouse;
                formatedPayment.comments = payment.comments;
                formatedPayment.date = payment.date;
                formatedPayment.isUnidentified = payment.isUnidentified?1:0;
                formatedPayments.push(formatedPayment);
            }
            vm.isReady = 1;
            return formatedPayments;
        }

        function definePaymentMethod(payment) {
            switch (payment.metPago) {
            case "efectivo":
                return "Efectivo";
                break;
            case "cheque":
                return "Cheque";
                break;
            case "transferencia":
                return "Transferencia";
                break;
            case "tarjeta":
                return "Tarjeta";
                break;
            case "deposito":
                return "Deposito en banco";
                break;
            }
            return 1;
        }
    }
}

)();
